<template>
  <v-card class="mx-auto my-auto session-expired" elevation="0">
    <v-card-text class="pa-10 text-center">
      <v-icon class="icon-large mb-5" x-large>mdi-history</v-icon>
      <p class="text-h4 text--primary mb-1">
        Your session has expired due to inactivity.
      </p>
      <p class="text--secondary text--small mb-10">
        To access Syncio again, please log back via Shopify.
      </p>
      <v-btn elevation="0" class="px-16 py-4" color="primary rounded-lg" @click="relogin">
        Log Back In
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";
  import { RELOGIN_SHOPIFY } from "@/store/actions.type";

  export default {
    name: 'SessionExpired',
    computed: {
      ...mapState('shop', [ 'currentShop' ]),
    },
    methods: {
      relogin() {
        let storeName = this.currentShop ? this.currentShop.store_domain : null;
        if (this.currentShop === null || this.currentShop.platform != 'shopify') {
          this.$router.replace({name: 'LoginPage'});
        } else {
          this.$store.dispatch(`auth/${RELOGIN_SHOPIFY}`, {storeName})
            .then((data) => {
              if (data.success === true && typeof data.redirect_url != "undefined"){
                let redirectUrl = data.redirect_url;
                redirectUrl += process.env.BASE_URL+'/dashboard'; // because dashboard current handle other initial loading.
                window.location.href = redirectUrl;
              }else{
                this.$router.replace({name: 'LoginPage'});
              }
            });
        }
      }
    }
  }
</script>
